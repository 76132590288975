 <template>
  <div>
    <CModal color="primary" :show="showModal">
      <template v-slot:header>
        <h5 class="modal-title">Customer Details</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(showModal)"
        >
          ×
        </button>
      </template>
      <p>
         Name:
        {{ customer ? customer.first_name + " " + customer.last_name : null }}.
      </p>
      <p>
         Email:
        {{ customer ? customer.email : null }}.
      </p>
      <p>
         Contact No:
        {{ customer ? customer.contact_no : null }}.
      </p>
      <p>
         Address:
        {{ customer ? customer.address : null }}.
      </p>      
     
      <template v-slot:footer>
        <CButton color="primary" @click="closeModal(showModal)">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CustomerView",
  computed: {
    ...mapState({
      showModal: (state) => state.customers.showModal,
      customer: (state) => state.customers.showCustomer,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("customers/SHOW_MODAL", {
        bool: bool,
        customer: null,
      });
    },
  },
};
</script>